<template>

    <Row cmpt="LabelRow">
      <Column :width="15" :class="'rc-text-label ' + styling()">
        <slot name="label"></slot>
      </Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'portals-shared-library-label-label-row',
  components: {
    Row,
    Column,
  },
  props: {
    align: { type: String, default: "centre" },
    cmpt: { type: String, default: "label" },
    labelFontSize: { type: String, default: "medium" },
    labelWidth: { type: Number, default: 15 },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    styling: function() {
      var value = "";
      
      if (this.align === "left") {
        value += "rc-text-left";
        
      } else if (this.align === "right") {
        value += "rc-text-right";
        
      } else {
        value += "rc-text-centre";
      }
      
      if (this.labelFontSize) {
        value += " rc-text-" + this.labelFontSize;
      }
      
      return value;
    }
  },
}
</script>